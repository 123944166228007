import { Wrapper } from "@googlemaps/react-wrapper";

export const GoogleMapsWrapper = ({
  children,
  apiKey
}: {
  children: React.ReactNode;
  apiKey: string | undefined;
}) => {
  if (!apiKey) return null;
  return (
    <Wrapper apiKey={apiKey ?? ""} libraries={["geometry", "places", "marker"]}>
      {children}
    </Wrapper>
  );
};
